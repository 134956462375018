import React from 'react';

const Tools = () => {
  const tools = [
    {
      name: 'llm-term',
      description: 'A Rust-based CLI tool that generates and executes terminal commands using LLMs',
      link: 'https://github.com/dh1011/llm-term'
    },
    {
      name: 'Bookmarks Manager',
      description: 'A simple web application that simplifies bookmark management through semantic search.',
      link: 'https://github.com/dh1011/semantic-bookmark-manager'
    },
    {
      name: 'makesound',
      description: 'A script that used to make the playlist above.',
      link: 'https://gist.github.com/dh1011/c4c8c62f5b2c028e6330c17550517be7'
    }
  ];

  return (
    <section className="space-y-4 min-h-[550px]">
      <h2 className="text-sm text-[#8c8f99]">Helpful Tools I Made</h2>
      <div className="space-y-3">
        {tools.map((tool, index) => (
          <div key={index}>
            <p className="text-[#cfd2d8] font-bold">- {tool.name}</p>
            <p className="text-sm text-[#8c8f99] mb-1">{tool.description}</p>
            <a
              href={tool.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#a879ff] text-sm hover:underline hover:text-[#8c8f99]"
            >
              {tool.link}
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Tools;