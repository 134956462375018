import React from 'react';

const Links = () => {
  const links = [
    {
      name: 'Email',
      href: 'mailto:mail@duyhua.com',
      text: 'mail@duyhua.com'
    },
    {
      name: 'GitHub',
      href: 'https://github.com/dh1011',
      text: 'github.com/dh1011'
    },
    {
      name: 'Twitter',
      href: 'https://x.com/_lee337_',
      text: 'x.com/_lee337_'
    }
  ];

  return (
    <footer className="space-y-3 mt-8">
      <div className="space-y-4 mt-3">
        <h2 className="text-sm text-[#8c8f99]">Links</h2>
        {links.map((link, index) => (
          <div key={index}>
            <p className="text-[#cfd2d8] font-bold">- {link.name}</p>
            <a
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#a879ff] text-sm hover:underline hover:text-[#8c8f99]"
            >
              {link.text}
            </a>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Links;