import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import Playlist from './components/Playlist';
import News from './components/News';
import Tools from './components/Tools';
import Links from './components/Links';

function App() {
  const [activeTab, setActiveTab] = useState('news');

  const tabs = [
    { id: 'news', label: 'News', icon: 'material-symbols:news' },
    { id: 'playlist', label: 'Playlist', icon: 'material-symbols:playlist-play' },
    { id: 'tools', label: 'Tools', icon: 'material-symbols:tools-power-drill' }
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'news':
        return <News />;
      case 'playlist':
        return <Playlist />;
      case 'tools':
        return <Tools />;
      default:
        return <News />;
    }
  };

  return (
    <div className="min-h-screen bg-[#0e0f1b] text-[#cfd2d8] flex items-center justify-center p-12">
      <main className="max-w-4xl w-full space-y-8 text-left font-sans">
        {/* Profile Section */}
        <header className="space-y-1">
          <h1 className="text-lg font-semibold text-[#cfd2d8]">
            Duy Hua <span className="text-[#a879ff]">/yue hwa/</span>
          </h1>
          <p className="text-sm text-[#cfd2d8]">
            Software engineer based in the <span className="text-[#a879ff]">United States</span>.
          </p>
        </header>

        {/* Tabs Navigation */}
        <nav className="border-b border-[#2a2b36]">
          <div className="flex space-x-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`pb-2 text-sm transition-colors duration-150 flex items-center gap-1 ${
                  activeTab === tab.id
                    ? 'text-[#a879ff] border-b-2 border-[#a879ff]'
                    : 'text-[#8c8f99] hover:text-[#cfd2d8]'
                }`}
              >
                <Icon icon={tab.icon} className="w-4 h-4" />
                {tab.label}
              </button>
            ))}
          </div>
        </nav>

        {/* Content Section */}
        <section className="min-h-[600px]">
          {renderContent()}
        </section>

        {/* Links Section - Always visible */}
        <Links />
      </main>
    </div>
  );
}

export default App;
