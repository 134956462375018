import React, { useState, useRef, useEffect } from 'react';

// Song list with local file paths
const songs = [
  { title: 'A Project of One\'s Own', url: '/audio/a_project_of_one_own.mp3' },
  { title: 'Alien Truth', url: '/audio/alien_truth.mp3' },
  { title: 'Beyond Smart', url: '/audio/beyond_smart.mp3' },
  { title: 'Crazy New Ideas', url: '/audio/crazy_new_ideas.mp3' },
  { title: 'Fierce Nerds', url: '/audio/fierce_nerds.mp3' },
  { title: 'Heresy', url: '/audio/heresy.mp3' },
  { title: 'How People Get Rich Now', url: '/audio/how_people_get_rich_now.mp3' },
  { title: 'How to Do Great Work', url: '/audio/how_to_do_great_work.mp3' },
  { title: 'How to Get New Ideas', url: '/audio/how_to_get_new_ideas.mp3' },
  { title: 'How to Start a Google', url: '/audio/how_to_start_google.mp3' },
  { title: 'How to Work Hard', url: '/audio/how_to_work_hard.mp3' },
  { title: 'Is There Such a Thing as Good Taste', url: '/audio/is_there_such_a_thing_as_good_taste.mp3' },
  { title: 'Putting Ideas Into Words', url: '/audio/putting_ideas_into_words.mp3' },
  { title: 'Superlinear Returns', url: '/audio/superlinear_returns.mp3' },
  { title: 'The Best Essay', url: '/audio/the_best_essay.mp3' },
  { title: 'The Need to Read', url: '/audio/the_need_to_read.mp3' },
  { title: 'The Reddits', url: '/audio/the_reddits.mp3' },
  { title: 'Weird Languages', url: '/audio/weird_languages.mp3' },
  { title: 'What I\'ve Learned from Users', url: '/audio/what_ive_learned_from_user.mp3' },
  { title: 'What You Want to Want', url: '/audio/what_you_want_to_want.mp3' }
];

const Playlist = () => {
  const [currentSongIndex, setCurrentSongIndex] = useState(null);
  const audioRef = useRef(null);

  const playSong = (index) => {
    setCurrentSongIndex(index);
  };

  const handleNextSong = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length);
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener('ended', handleNextSong);
      return () => {
        audio.removeEventListener('ended', handleNextSong);
      };
    }
  }, [currentSongIndex]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio && currentSongIndex !== null) {
      // Load the new song whenever `currentSongIndex` changes
      audio.load();
      audio.play();
    }
  }, [currentSongIndex]);

  return (
    <section className="text-left">
      <h2 className="text-sm text-[#8c8f99] mb-4">Paul Graham Essays Playlist</h2>
      <div className="max-h-[550px] overflow-y-auto pr-2" style={{ scrollbarWidth: 'thin' }}>
        <ul className="space-y-2">
          {songs.map((song, index) => (
            <li
              key={index}
              onClick={() => playSong(index)}
              className={`p-2 rounded cursor-pointer text-[#cfd2d8] text-sm transition-all duration-200 flex items-center
                ${currentSongIndex === index ? 'text-[#a879ff]' : 'hover:bg-[#1f1f2e]'}`}
            >
              {currentSongIndex === index && (
                <div className="w-2 h-2 rounded-full bg-[#3b82f6] mr-2"></div>
              )}
              {song.title}
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        {currentSongIndex !== null && (
          <audio ref={audioRef} controls className="w-full mt-3 bg-[#0e0f1b] text-[#cfd2d8]" autoPlay>
            <source src={songs[currentSongIndex].url} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
      </div>
    </section>
  );
};

export default Playlist;
