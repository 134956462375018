import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const News = () => {
  const [updates, setUpdates] = useState({ startups: [], openSources: [], aiNews: [] });
  const [loading, setLoading] = useState(true);

  const fetchUpdates = async () => {
    try {
      setLoading(true);

      const [githubResponse, productHuntResponse] = await Promise.allSettled([
        fetch('https://dh1011-scrapgithubpythontrending.web.val.run'),
        fetch('https://dh1011-scrapeproducthunttrend.web.val.run'),
      ]);

      const startups = [];
      const openSources = [];

      if (githubResponse.status === 'fulfilled' && githubResponse.value.ok) {
        const githubData = await githubResponse.value.json();
        openSources.push(
          ...githubData.map((item) => ({
            title: item.title,
            content: `[${item.description}](${item.link})`,
          }))
        );
      }

      if (productHuntResponse.status === 'fulfilled' && productHuntResponse.value.ok) {
        const productHuntData = await productHuntResponse.value.json();
        startups.push(
          ...productHuntData.map((item) => ({
            title: item.title,
            content: item.description,
          }))
        );
      }

      const rssResponse = await fetch('https://dh1011-getrsssummary.web.val.run');
      const aiNews = [];

      if (rssResponse.ok) {
        const rssData = await rssResponse.json();
        aiNews.push(
          ...rssData.map((item) => ({
            title: item.title,
            content: item.summary,
          }))
        );
      }

      const updatesData = { startups, openSources, aiNews };
      const today = new Date().toISOString().split('T')[0]; // Get today's date
      localStorage.setItem('newsUpdates', JSON.stringify({ date: today, data: updatesData })); // Store with date
      setUpdates(updatesData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching updates:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const persistedData = localStorage.getItem('newsUpdates');
    const today = new Date().toISOString().split('T')[0]; // Get today's date

    if (persistedData) {
      const parsedData = JSON.parse(persistedData);

      if (parsedData.date === today) {
        setUpdates(parsedData.data); // Use stored data if date matches
        setLoading(false);
      } else {
        fetchUpdates(); // Fetch new data if date doesn't match
      }
    } else {
      fetchUpdates(); // Fetch data if none exists in localStorage
    }

    // Expose backdoor refresh function to the console
    window.refreshNews = () => {
      console.log('Refreshing news updates...');
      fetchUpdates();
    };
  }, []);

  if (loading) {
    return <div className="text-sm text-[#8c8f99]">Loading...</div>;
  }

  if (
    updates.startups.length === 0 &&
    updates.openSources.length === 0 &&
    updates.aiNews.length === 0
  ) {
    return <div className="text-sm text-[#8c8f99]">No updates available. All sources failed to respond.</div>;
  }

  return (
    <section>
      <div className="space-y-8 max-h-[600px] overflow-y-auto pr-4" style={{ scrollbarWidth: 'thin' }}>
        {updates.startups.length > 0 && (
          <div>
            <h2 className="text-xl font-bold mb-4 border-b border-gray-600 pb-2">
              Trending Startups
            </h2>
            <div className="space-y-4">
              {updates.startups.map((item, index) => (
                <article key={index} className="space-y-2">
                  <strong className="text-[#a879ff]">{item.title}</strong>
                  <ReactMarkdown>{item.content}</ReactMarkdown>
                </article>
              ))}
            </div>
          </div>
        )}
        {updates.openSources.length > 0 && (
          <div>
            <h2 className="text-xl font-bold mb-4 border-b border-gray-600 pb-2">
              Trending Open Sources
            </h2>
            <div className="space-y-4">
              {updates.openSources.map((item, index) => (
                <article key={index} className="space-y-2">
                  <strong className="text-[#a879ff]">{item.title}</strong>
                  <ReactMarkdown
                    components={{
                      a: ({ href, children }) => (
                        <a
                          href={href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[#8c8f99] hover:text-[#a879ff] transition"
                        >
                          {children}
                        </a>
                      ),
                    }}
                  >
                    {item.content}
                  </ReactMarkdown>
                </article>
              ))}
            </div>
          </div>
        )}
        {updates.aiNews.length > 0 && (
          <div>
            <h2 className="text-xl font-bold mb-4 border-b border-gray-600 pb-2">AI News</h2>
            <div className="space-y-4">
              {updates.aiNews.map((item, index) => (
                <article key={index} className="space-y-2">
                  <strong className="text-[#a879ff]">{item.title}</strong>
                  <ReactMarkdown>{item.content}</ReactMarkdown>
                </article>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default News;
